import React from "react";

import moment from "moment";
import { useTranslation } from "react-i18next";

import CustomButton from "../../../components/button/button";
import { ErrorAccountCardExpired } from "../../../components/error/ErrorAccountCardExpired";
import Input from "../../../components/input/Input";
import { Client } from "../../../context/clientContext";
import { useAxios } from "../../../services/hook/requestsService";
import { isCardExpired } from "../../../services/tools";

export const CreditCard: React.FC<{ clientValues: Client | null }> = ({ clientValues }) => {
    const { t } = useTranslation();
    const { putData } = useAxios();

    const submitUpdateCard = async () => {
        const response = await putData(null, `client/payment-method`, undefined);
        window.location = response.data.url;
    };

    return (
        <div className="middle-container my-account-view">
            <div
                className={`w-100 d-flex align-items-center justify-content-between mb-3 mb-lg-5${
                    isCardExpired(clientValues?.creditCardExpirationDate) ? " mb-5" : ""
                }`}
            >
                <div className="d-flex  align-items-center">
                    <h2 className="mb-0 me-lg-4 account-title">
                        {t("account-management.cb-information")}
                    </h2>
                </div>
                <div className="d-flex align-items-center">
                    {isCardExpired(clientValues?.creditCardExpirationDate) && (
                        <div className="me-lg-4 expired-content">
                            <ErrorAccountCardExpired />
                        </div>
                    )}

                    <CustomButton
                        disabled={!clientValues?.cardLast4}
                        classNameType="mainWhite"
                        onClick={submitUpdateCard}
                        buttonText={t("account-management.update-cb")}
                        buttonType="button"
                    />
                </div>
            </div>
            <div className="row flex-row mb-0 mb-lg-2">
                <div className="col-12 col-lg-4 mb-3">
                    <Input
                        inputType="text"
                        placeholder="XXXX XXXX XXXX 1234"
                        readOnly
                        withLabel
                        withSkeleton
                        groupClassName={"inline mb-0"}
                        labelTranslation={t("account-management.cb-number")}
                        value={
                            clientValues?.cardLast4
                                ? `XXXX XXXX XXXX ${clientValues?.cardLast4}`
                                : undefined
                        }
                    />
                </div>
                <div className="col col-12 col-lg-4 mb-3">
                    <Input
                        inputType="text"
                        placeholder="MM/YY"
                        readOnly
                        withLabel
                        withSkeleton
                        groupClassName={"inline mb-0"}
                        labelTranslation={t("account-management.cb-expiration-date")}
                        value={
                            clientValues?.creditCardExpirationDate
                                ? moment(clientValues?.creditCardExpirationDate).format("MM/YY")
                                : undefined
                        }
                    />
                </div>
            </div>
            <div className="col-12 col-lg-4 mb-3">
                <Input
                    inputType="text"
                    placeholder="MM/YY"
                    readOnly
                    withLabel
                    withSkeleton
                    groupClassName={"inline mb-0"}
                    labelTranslation={t("account-management.cb-currency")}
                    value={
                        clientValues?.currency
                            ? clientValues?.currency === "EUR"
                                ? "Euros (EUR)"
                                : "Dollars (USD)"
                            : undefined
                    }
                />
            </div>
        </div>
    );
};
