import { useQuery, UseQueryResult } from "@tanstack/react-query";

import { getConsumption } from "../../api/consumption.api";
import { GetConsumptionParams, GetConsumptionResponse } from "../../types/consumption";

export const useGetConsumption = (
    params: GetConsumptionParams
): UseQueryResult<GetConsumptionResponse> => {
    return useQuery(["getConsumption", params], async () => getConsumption(params));
};
