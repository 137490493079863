import React, { CSSProperties, useState } from "react";

import ProgressBar from "@ramonak/react-progress-bar";
import SweetAlert from "react-bootstrap-sweetalert";

import useWindowDimensions from "../../hook/useWindowDimensions";
import { Checkbox } from "../checkbox/checkbox";
import RoundedLoader from "../loader/RoundedLoaderAnimated";

import "./modal.scss";
export interface IModal {
    show?: boolean;
    title?: string;
    element?: any;
    elementPositionRight?: boolean;
    elementPositionMiddle?: boolean;
    message?: string | React.ReactNode;
    messagePartOne?: string;
    messagePartTwo?: string;
    content?: any;
    customClass?: string;
    confirmBtnCssClass?: string;
    confirmBtnDisabled?: boolean;
    cancelBtnCssClass?: string;
    onConfirm?: any;
    onCancel?: any;
    confirmBtnText?: string;
    customButtons?: any;
    showConfirm?: boolean;
    showCancel?: boolean;
    br?: any;
    cancelBtnText?: string;
    progress?: number | null;
    loading?: boolean;
    withCheckBox?: boolean;
    checkboxValue?: any;
    checkboxLabelStyle?: CSSProperties;
    input?: boolean;
    footerData?: React.ReactNode;
}

const Modal: React.FC<IModal> = ({
    show = false,
    title = "",
    element,
    elementPositionRight = false,
    elementPositionMiddle = false,
    message,
    messagePartOne,
    messagePartTwo,
    content,
    customClass,
    confirmBtnCssClass = "btn-main",
    cancelBtnCssClass,
    onConfirm = () => void 0,
    onCancel = () => void 0,
    confirmBtnText,
    customButtons,
    showConfirm = true,
    showCancel,
    br,
    cancelBtnText = "Cancel",
    progress,
    loading = false,
    withCheckBox,
    checkboxLabelStyle,
    checkboxValue,
    footerData: FooterData
}) => {
    const [checkbox, setCheckbox] = useState(false);
    const { width } = useWindowDimensions();

    return (
        //@ts-ignore
        <SweetAlert
            show={show}
            title={title}
            onCancel={onCancel}
            onConfirm={() => onConfirm(checkbox)}
            closeOnClickOutside={false}
            customButtons={customButtons}
            customClass={"modal " + customClass}
            confirmBtnCssClass={confirmBtnCssClass}
            confirmBtnText={confirmBtnText}
            cancelBtnCssClass={"btn-white-main " + cancelBtnCssClass}
            cancelBtnText={cancelBtnText}
            focusConfirmBtn={false}
            showCancel={showCancel && !loading}
            showConfirm={!loading && !progress && showConfirm}
        >
            <p className="modal-message">
                {elementPositionRight === true ? (
                    <>
                        {message}
                        {br === true ? <br /> : null}
                        <strong className="primary"> {element}</strong>
                    </>
                ) : elementPositionMiddle === true ? (
                    <>
                        {messagePartOne}
                        <strong className="primary">{element}</strong>
                        <br />
                        {messagePartTwo}
                    </>
                ) : (
                    <>
                        <strong className="primary">{element} </strong>
                        {br === true ? <br /> : null}
                        {message}
                        <br />
                    </>
                )}
            </p>
            {content && content}
            {withCheckBox && (
                <Checkbox
                    value={checkboxValue as string}
                    isChecked={checkbox as boolean}
                    onClick={() => setCheckbox(!checkbox)}
                    labelText={"Notify me per email when processing is completed"}
                    checkboxLabelStyle={checkboxLabelStyle}
                    labelTextStyle={{ left: `${width < 992 ? "0" : "40px"}` }}
                    checkMarkStyle={{
                        transform: `${width < 992 ? "none" : "translateX(170%)"}`
                    }}
                />
            )}
            {loading && <RoundedLoader isLoading={loading} />}
            {progress && (
                <ProgressBar
                    completed={progress || 0}
                    bgColor="#47dc95"
                    transitionDuration="250ms"
                />
            )}
            {FooterData && FooterData}
        </SweetAlert>
    );
};

export default Modal;
