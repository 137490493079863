import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import CustomButton from "../../components/button/button";
import Input from "../../components/input/Input";
import TaskButton from "../../components/mobile/task/TaskButton";
import { config } from "../../config/config";
import { useAxios } from "../../services/hook/requestsService";
import { sleep } from "../../services/tools";

// Style
import "../../static/scss/tooltip.scss";

// Icon
import { ReactComponent as IconCopy } from "../../static/icons/icon-copy.svg";
// import { WebhookSection } from './sections/webhook configuration/WebhooksSection';

const Tooltip = ({ show = true }: { show?: boolean }) => {
    const { t } = useTranslation();

    if (!show) return null;
    return (
        <div className="tooltip-mobile-container" aria-hidden={!show}>
            <div className="tooltip-icon">
                <IconCopy fill="white" />
            </div>

            {t("developer.success-copied")}
        </div>
    );
};

export const Developer = () => {
    const { getData } = useAxios();
    const [copied, setCopied] = useState<"client" | "secret" | undefined>();
    const [loading, setLoading] = useState(false);
    const [clientId, setClientId] = useState("");
    const [secretId, setSecretId] = useState("");
    const { t } = useTranslation();

    useEffect(() => {
        if (!loading) {
            (async () => {
                setLoading(true);
                const { data } = await getData(null, "client", { params: { withId: true } });
                if (data.clientId && data.secretId) {
                    setClientId(data.clientId);
                    setSecretId(data.secretId);
                }
                setLoading(false);
            })();
        }
    }, []);

    const copyToClipBoard = async (id: "client" | "secret") => {
        try {
            await navigator.clipboard.writeText(id === "client" ? clientId : secretId);
            setCopied(id);
            await sleep(2000);
            setCopied(undefined);
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <>
            <div className="middle-container middle-container-developer mb-3">
                <h2 className="mb-lg-5 mb-3 middle-container-title">
                    {t("developer.api-credentials")}
                </h2>

                <div className="d-flex align-items-start align-items-lg-center mb-3 flex-column flex-lg-row gap-4">
                    <span className="me-lg-5 mb-lg-0 mb-2 text-bold">
                        {t("developer.client-id")}
                    </span>
                    <Input
                        inputType="text"
                        placeholder={t("developer.client-id")}
                        disable
                        readOnly
                        withLabel={false}
                        withSkeleton
                        style="border"
                        groupClassName={"inline mb-0 w-50"}
                        value={clientId}
                    />
                    <div className="position-relative w-100-mobile text-center text-lg-left mt-lg-0 mt-3">
                        <CustomButton
                            disabled={!clientId}
                            buttonType="button"
                            onClick={() => copyToClipBoard("client")}
                            buttonText={t("developer.copy-id-button", {
                                id: t("developer.client-id")
                            })}
                            classNameType="mainWhite"
                        />
                        <Tooltip show={copied === "client"} />
                    </div>
                </div>

                <div className="d-flex align-items-start align-items-lg-center mb-3 flex-column flex-lg-row gap-4">
                    <span className="me-lg-5 mb-lg-0 mb-2 text-bold">
                        {t("developer.secret-id")}
                    </span>
                    <Input
                        inputType="text"
                        placeholder={t("developer.secret-id")}
                        disable
                        readOnly
                        withLabel={false}
                        withSkeleton
                        style="border"
                        groupClassName={"inline mb-0 w-50"}
                        value={secretId}
                    />
                    <div className="position-relative w-100-mobile text-center text-lg-left mt-lg-0 mt-3">
                        <CustomButton
                            disabled={!secretId}
                            buttonType="button"
                            onClick={() => copyToClipBoard("secret")}
                            buttonText={t("developer.copy-id-button", {
                                id: t("developer.secret-id")
                            })}
                            classNameType="mainWhite"
                        />
                        <Tooltip show={copied === "secret"} />
                    </div>
                </div>
            </div>

            <div className="middle-container middle-container-developer mb-3">
                <h2 className="mb-lg-5 mb-3 middle-container-title">
                    {t("developer.documentation-support")}
                </h2>

                <div className="row flex-row align-items-center mb-3">
                    <div className="col-12 col-lg-4">
                        <span className="me-5 text-bold">{t("developer.documentation-link")}</span>
                    </div>
                    <div className="col-12 col-lg-6">
                        <a
                            style={{ textDecoration: "underline", color: "#47dc95" }}
                            href={config.documentation}
                            title="documentation"
                            target="_blank"
                            rel="noreferrer"
                        >
                            {config.documentation}
                        </a>
                    </div>
                </div>

                <div className="row flex-row align-items-center mb-3">
                    <div className="col-12 col-lg-4">
                        <span className="me-5 text-bold">{t("developer.support")}</span>
                    </div>
                    <div className="col-12 col-lg-6">
                        <a
                            style={{ textDecoration: "underline", color: "#47dc95" }}
                            href={`mailto:${config.supportMail}`}
                            title="supportMail"
                            target="_blank"
                            rel="noreferrer"
                        >
                            {config.supportMail}
                        </a>
                    </div>
                </div>
            </div>
            {/* <WebhookSection /> */}
            <TaskButton />
        </>
    );
};
