import { useContext, useEffect } from "react";

import { useRecoilState } from "recoil";

import { trialConsumtionState } from "../recoil/trialConsumption.atom";
import { useGetClient } from "./api/client";
import { useGetConsumption } from "./queries/useConsumption";
import { socketContext } from "../context/socketContext";

export const useTrialConsumption = () => {
    const [state, setState] = useRecoilState(trialConsumtionState);
    const client = useGetClient();
    const consumptionApi = useGetConsumption({ isTrial: client.data?.isTrial });
    const socket = useContext(socketContext);

    useEffect(() => {
        const consumption = consumptionApi?.data;
        // 100 MB in Bytes
        let totalCount = 104_857_600;
        for (const { size } of consumption?.totalCounts || []) {
            totalCount -= parseInt(size);
        }
        setState(totalCount);
        consumptionApi.refetch();
    }, [consumptionApi.data, socket.task]);

    return {
        clientLoading: client.isLoading,
        consumptionLoading: consumptionApi.isLoading,
        isTrial: client.data?.isTrial,
        trialConsumption: state
    };
};
