import { Dispatch, SetStateAction, useCallback, useContext, useEffect, useState } from "react";

import moment from "moment";
import { Trans, useTranslation } from "react-i18next";

import { Form, Formik } from "formik";
import * as Yup from "yup";

import { Textarea } from "components/textarea/Textarea";
import CustomButton from "components/button/button";
import { Checkbox } from "components/checkbox/checkbox";
import Input from "components/input/Input";
import { RoundedLoader } from "components/loader/RoundedLoader";
import Modal, { IModal } from "components/modal/Modal";
import Toggle from "components/toggle/Toggle";

import {
    licensesContext,
    AutomaticRenewalFeedbackForm,
    AutomaticRenewalFeedbackValues,
    convertFeedbackFormToString
} from "context/licensesContext";

import { License, PaymentRecurrence } from "types/license";

interface FormSurveyProps {
    license: License;
    setModalState: Dispatch<SetStateAction<IModal>>;
}

const FormSurvey: React.FC<FormSurveyProps> = ({ license, setModalState }) => {
    const { t } = useTranslation();
    const { updateLicenseAutomaticRenewal } = useContext(licensesContext);

    const initialValues: AutomaticRenewalFeedbackForm = {
        expectation: false,
        noMoreNeeded: false,
        highPrice: false,
        techIssue: false,
        techDetails: "",
        other: false,
        otherDetails: "",
        acceptContact: false
    };

    const validationSchema = Yup.object({
        techDetails: Yup.string().when("techIssue", {
            is: true,
            then: Yup.string().required(),
            otherwise: Yup.string().strip()
        }),
        otherDetails: Yup.string().when("other", {
            is: true,
            then: Yup.string().required(),
            otherwise: Yup.string().strip()
        })
    });

    /**
     * Gère la soumission de la mise à jour du renouvellement automatique pour une licence.
     * Fermeture de la modal associée.
     *
     * @param {License} license - L'objet licence à mettre à jour, incluant un identifiant unique (uuid).
     * @param {AutomaticRenewalFeedbackForm} [values] - Les valeurs facultatives du formulaire de retour d'information
     * concernant le renouvellement automatique. Ces valeurs seront converties en chaîne si présentes.
     */
    const updateLicenseAutomaticRenewalSubmit = (values?: AutomaticRenewalFeedbackForm) => {
        let valuesAutomaticRenewal: AutomaticRenewalFeedbackValues = {
            automaticRenewal: false
        };
        if (values) {
            valuesAutomaticRenewal.feedbackForm = convertFeedbackFormToString(values);
        }
        updateLicenseAutomaticRenewal(license.uuid, valuesAutomaticRenewal);
        setModalState((prev) => ({ ...prev, show: false }));
    };

    return (
        <Formik
            onSubmit={updateLicenseAutomaticRenewalSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
        >
            {({
                values,
                setFieldValue,
                setValues,
                touched,
                handleBlur,
                errors,
                isValid,
                dirty
            }) => {
                return (
                    <Form>
                        <div className="mb-2">
                            <Checkbox
                                value="expectation"
                                isChecked={values.expectation}
                                onClick={(e) => setFieldValue("expectation", e.target.checked)}
                                labelText={t(
                                    "services-management.plugin.modal.survey.form.expectation"
                                )}
                                customClass="mb-0"
                            />
                        </div>

                        <div className="mb-2">
                            <Checkbox
                                value="noMoreNeeded"
                                isChecked={values.noMoreNeeded}
                                onClick={(e) => setFieldValue("noMoreNeeded", e.target.checked)}
                                labelText={t(
                                    "services-management.plugin.modal.survey.form.no-more-needed"
                                )}
                                customClass="mb-0"
                            />
                        </div>

                        <div className="mb-2">
                            <Checkbox
                                value="highPrice"
                                isChecked={values.highPrice}
                                onClick={(e) => setFieldValue("highPrice", e.target.checked)}
                                labelText={t(
                                    "services-management.plugin.modal.survey.form.high-price"
                                )}
                                customClass="mb-0"
                            />
                        </div>

                        <div className="mb-2">
                            <Checkbox
                                value="techIssue"
                                isChecked={values.techIssue}
                                onClick={(e) => setFieldValue("techIssue", e.target.checked)}
                                labelText={t(
                                    "services-management.plugin.modal.survey.form.tech-issue"
                                )}
                                customClass="mb-0"
                            />
                            {values.techIssue && (
                                <div>
                                    <Textarea
                                        name="techDetails"
                                        value={values.techDetails}
                                        onChange={(e) =>
                                            setFieldValue("techDetails", e.target.value)
                                        }
                                        onBlur={handleBlur}
                                        customClass={`mt-2 ${touched.techDetails && errors.techDetails ? "is-invalid" : ""}`}
                                        placeholder={t(
                                            "services-management.plugin.modal.survey.form.tech-details"
                                        )}
                                    />
                                </div>
                            )}
                        </div>

                        <div className="mb-4">
                            <Checkbox
                                value="other"
                                isChecked={values.other}
                                onClick={(e) => setFieldValue("other", e.target.checked)}
                                labelText={t("services-management.plugin.modal.survey.form.other")}
                                isDisabled={values.otherDetails !== ""}
                                customClass="mb-0"
                            />
                            <div>
                                <Textarea
                                    name="otherDetails"
                                    value={values.otherDetails}
                                    onChange={(e) =>
                                        setValues((prev) => ({
                                            ...prev,
                                            otherDetails: e.target.value,
                                            other: e.target.value.trim() !== "" ? true : false
                                        }))
                                    }
                                    onBlur={handleBlur}
                                    customClass={`mt-2 ${touched.otherDetails && errors.otherDetails ? "is-invalid" : ""}`}
                                    placeholder={t(
                                        "services-management.plugin.modal.survey.form.other-details"
                                    )}
                                />
                            </div>
                        </div>

                        <div className="mb-2">
                            <Checkbox
                                value="acceptContact"
                                isChecked={values.acceptContact}
                                onClick={(e) => setFieldValue("acceptContact", e.target.checked)}
                                labelText={t(
                                    "services-management.plugin.modal.survey.form.accept-contact"
                                )}
                                customClass="mb-0"
                            />
                        </div>

                        <div className="d-flex align-items-center justify-content-center gap-2 mt-4">
                            <CustomButton
                                onClick={() => updateLicenseAutomaticRenewalSubmit()}
                                buttonType="button"
                                classNameType="mainWhite"
                                buttonText={t("services-management.plugin.modal.survey.form.skip")}
                            />

                            <CustomButton
                                buttonType="submit"
                                buttonText={t("services-management.plugin.modal.survey.form.send")}
                                disabled={!isValid || !dirty}
                            />
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};

export const BluritPlugin: React.FC = () => {
    const { t } = useTranslation();
    const { data, updateLicenseAutomaticRenewal, loading, error } = useContext(licensesContext);
    const [modalState, setModalState] = useState({} as IModal);
    const license = data?.[0]; // TODO For the moment, we only have one license

    const openModalPluginAutomaticRenewal = useCallback(
        (license: License) => {
            const automaticRenewalUpdated = !license.automaticRenewal;

            // Default date end for monthly subscriptions
            let dateEnd = moment.utc().endOf("month").endOf("day").toDate();

            if (license.paymentRecurrence === PaymentRecurrence.YEARLY) {
                // For yearly subscriptions, find the next occurrence from the start date
                const startDate = moment(license.dateStart);
                const today = moment();
                const yearsDiff = today.diff(startDate, "years");
                const nextOccurrence = startDate.clone().add(yearsDiff + 1, "year");
                dateEnd = nextOccurrence.toDate();
            }

            if (!automaticRenewalUpdated) {
                setModalState({
                    show: true,
                    showCancel: true,
                    title: t("services-management.plugin.modal.automatic-renewal.title.deactivate"),
                    message: t(
                        "services-management.plugin.modal.automatic-renewal.message.deactivate",
                        {
                            dateEnd: moment.utc(dateEnd).format("LL")
                        }
                    ),
                    onConfirm: () => {
                        setModalState({ ...modalState, show: false });
                        openModalCancellationSurvey(license);
                    },
                    onCancel: () => setModalState({ ...modalState, show: false }),
                    confirmBtnText: t("services-management.plugin.modal.confirm"),
                    cancelBtnText: t("services-management.plugin.modal.cancel")
                });
            } else {
                updateLicenseAutomaticRenewal(license.uuid, {
                    automaticRenewal: automaticRenewalUpdated
                });
            }
        },
        [license, t, updateLicenseAutomaticRenewal, modalState]
    );

    // Première modal de confirmation d'annulation de l'abonnement
    const openModalPluginTrialCancellation = useCallback((license: License) => {
        setModalState({
            show: true,
            showCancel: true,
            title: t("services-management.plugin.modal.trial.title.deactivate"),
            message: t("services-management.plugin.modal.trial.message.deactivate"),
            onConfirm: () => {
                setModalState({ ...modalState, show: false });
                openModalCancellationSurvey(license);
            },
            onCancel: () => setModalState({ ...modalState, show: false }),
            confirmBtnText: t("services-management.plugin.modal.confirm"),
            cancelBtnText: t("services-management.plugin.modal.cancel")
        });
    }, []);

    // Deuxième modal incluant le questionnaire
    const openModalCancellationSurvey = useCallback((license: License) => {
        setModalState({
            show: true,
            showConfirm: false,
            showCancel: false,
            title: t("services-management.plugin.modal.survey.title"),
            message: t("services-management.plugin.modal.survey.message"),
            content: <FormSurvey license={license} setModalState={setModalState} />
        });
    }, []);

    const getIsLicenseExpired = useCallback(
        (license: License) => moment().endOf("day").isAfter(license.dateEnd),
        []
    );

    const getIsLicenseTrial = useCallback(
        (license: License) => moment.utc(license.dateStart).add(7, "days").isAfter(moment()),
        []
    );

    useEffect(() => {
        if (error) {
            setModalState({
                show: true,
                showCancel: false,
                title: t("error.error-title"),
                message: t("error.internalError"),
                onConfirm: () => {
                    setModalState({ ...modalState, show: false });
                },
                confirmBtnText: t("services-management.plugin.modal.confirm"),
                cancelBtnText: t("services-management.plugin.modal.cancel")
            });
        }
    }, [error, modalState]);

    const renderRenewalToggle = (license: License) => {
        if (license.blocked || getIsLicenseTrial(license) || getIsLicenseExpired(license))
            return null;

        return (
            <div className="d-flex align-items-center gap-2">
                <span
                    className={`ms-2 ${license.automaticRenewal ? "color-primary" : "color-clear-blue-grey"}`}
                >
                    {t("services-management.plugin.renewal")}
                </span>
                <Toggle
                    isChecked={license.automaticRenewal}
                    onClick={() => openModalPluginAutomaticRenewal(license)}
                    disabled={loading}
                />
            </div>
        );
    };

    const renderCancelTrialButton = (license: License) => {
        if (!getIsLicenseTrial(license) || getIsLicenseExpired(license)) return null;

        return (
            <div className="d-flex align-items-center gap-2">
                <CustomButton
                    buttonText={t("services-management.plugin.cancel-trial", {
                        days: moment.utc(license.dateStart).add(7, "days").diff(moment(), "days")
                    })}
                    onClick={() => openModalPluginTrialCancellation(license)}
                    classNameType="mainWhite"
                />
            </div>
        );
    };

    const renderReactivateButton = (license: License) => {
        if (!getIsLicenseExpired(license) || license.blocked) return null;

        return (
            <div className="d-flex align-items-center gap-2">
                <CustomButton
                    buttonText={t("services-management.plugin.reactivate")}
                    onClick={() => openModalPluginReactivation(license)}
                />
            </div>
        );
    };

    const renderLicenseStatus = (license: License) => {
        if (license.blocked || getIsLicenseExpired(license))
            return t("services-management.plugin.deactivated").toUpperCase();
        return t("services-management.plugin.activated").toUpperCase();
    };

    const openModalPluginReactivation = useCallback(
        (license: License) => {
            setModalState({
                show: true,
                showCancel: true,
                title: t("services-management.plugin.modal.trial.title.activate"),
                message: t("services-management.plugin.modal.trial.message.activate"),
                onConfirm: () => {
                    setModalState({ ...modalState, show: false });
                    updateLicenseAutomaticRenewal(license.uuid, {
                        automaticRenewal: true
                    });
                },
                onCancel: () => setModalState({ ...modalState, show: false }),
                confirmBtnText: t("services-management.plugin.modal.confirm"),
                cancelBtnText: t("services-management.plugin.modal.cancel")
            });
        },
        [modalState, t]
    );

    const renderDateInformation = (license: License) => {
        if (license.blocked || getIsLicenseExpired(license)) return null;

        if (getIsLicenseTrial(license)) {
            return (
                <>
                    <Input
                        inputType="text"
                        readOnly
                        withLabel
                        withSkeleton
                        style="block"
                        labelTranslation={t("services-management.plugin.trial-ending-date")}
                        groupClassName={"mb-0"}
                        value={moment.utc(license.dateStart).add(7, "days").format("DD/MM/YYYY")}
                    />
                    <div className="account-more-info">
                        <p className="mt-3">{t("services-management.plugin.more-info.trial")}</p>
                    </div>
                </>
            );
        }

        return (
            <>
                {license.dateEnd && (
                    <>
                        <Input
                            inputType="text"
                            readOnly
                            withLabel
                            withSkeleton
                            style="block"
                            labelTranslation={t("services-management.plugin.commitment-end-date")}
                            groupClassName={"mb-0"}
                            value={moment.utc(license.dateEnd).format("DD/MM/YYYY")}
                        />
                    </>
                )}
                {!license.automaticRenewal && (
                    <div className="account-more-info">
                        <p className="mt-3">
                            {t("services-management.plugin.more-info.automatic-renewal")}
                        </p>
                    </div>
                )}
            </>
        );
    };

    if (!license) return null;

    return (
        <div className="middle-container my-account-view">
            {/* Header section */}
            <div className="row flex-row">
                <div className="w-100 d-flex align-items-center justify-content-between mb-3 mb-lg-5">
                    <div className="d-flex align-items-center account-title-container">
                        <h2 className="account-title mb-0 me-lg-4">
                            Blur<strong className="color-primary">It</strong> Plugin
                        </h2>
                        <RoundedLoader isLoading={loading} />
                    </div>
                    {renderCancelTrialButton(license)}
                    {renderReactivateButton(license)}
                    {renderRenewalToggle(license)}
                </div>
            </div>

            {/* License information section */}
            <div className="d-flex flex-column gap-3">
                {/* Status row */}
                <div>
                    <Input
                        inputType="text"
                        placeholder={`ex: ${t("account-management.plan-trial")}`}
                        readOnly
                        withLabel
                        withSkeleton
                        style="block"
                        labelTranslation={t("services-management.plugin.status")}
                        groupClassName={"mb-0"}
                        value={renderLicenseStatus(license)}
                    />
                </div>

                {/* Billing period - only shown for active licenses */}
                {!license.blocked && !getIsLicenseExpired(license) && (
                    <Input
                        inputType="text"
                        readOnly
                        withLabel
                        withSkeleton
                        style="block"
                        labelTranslation={t("services-management.plugin.billing-period")}
                        groupClassName={"mb-0"}
                        value={license.paymentRecurrence}
                    />
                )}

                {/* Date information row */}
                <div className="row align-items-center">{renderDateInformation(license)}</div>

                {/* Add expired license information */}
                {getIsLicenseExpired(license) && !license.blocked && (
                    <div className="account-more-info row align-items-center mt-3">
                        <h3 className="mb-2 me-lg-4">
                            {t("services-management.plugin.more-info.not-subscribed.title")}
                        </h3>
                        <p>
                            <Trans
                                i18nKey="services-management.plugin.more-info.not-subscribed.text"
                                components={{
                                    a: (
                                        <a
                                            href="/documentation"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        />
                                    ),
                                    strong: <strong />
                                }}
                            />
                        </p>
                    </div>
                )}
            </div>

            {/* Blocked license information */}
            {license.blocked && (
                <div className="account-more-info row align-items-center mt-3">
                    <h3 className="mb-2 me-lg-4">
                        {t("services-management.plugin.more-info.blocked.title")}
                    </h3>
                    <p>
                        <Trans
                            i18nKey="services-management.plugin.more-info.blocked.text"
                            components={{
                                a: <a href="mailto:support@blurit.io" />
                            }}
                        />
                    </p>
                </div>
            )}

            <Modal {...modalState} />
        </div>
    );
};
