import React, { createContext, useCallback, useEffect, useState } from "react";

import { useAxios } from "../services/hook/requestsService";
import { MyError, isMyError } from "../types/error";
import { License } from "../types/license";

export type AutomaticRenewalFeedbackForm = {
    expectation: boolean;
    noMoreNeeded: boolean;
    highPrice: boolean;
    techIssue: boolean;
    techDetails: string;
    other: boolean;
    otherDetails: string;
    acceptContact: boolean;
};

export type AutomaticRenewalFeedbackValues = { automaticRenewal: boolean; feedbackForm?: string };

export type LicenseState = {
    error: MyError | null;
    loading: boolean;
    data: License[] | null;
    refetch: boolean;
    getLicenses: () => void;
    updateLicenseAutomaticRenewal: (id: string, values: AutomaticRenewalFeedbackValues) => void;
    refetchData: () => void;
};

const initialState: LicenseState = {
    error: null,
    loading: false,
    data: null,
    refetch: false,
    getLicenses: () => null,
    updateLicenseAutomaticRenewal: () => null,
    refetchData: () => null
};

const FeedbackFormMap = {
    expectation: "EXPECTATION",
    noMoreNeeded: "NO_MORE_NEEDED",
    highPrice: "HIGH_PRICE",
    techIssue: "TECH_ISSUE",
    techDetails: "TECH_DETAILS",
    other: "OTHER",
    otherDetails: "OTHER_DETAILS",
    acceptContact: "ACCEPT_CONTACT"
} as const;

type FeedbackFormKeys = keyof typeof FeedbackFormMap;

export const convertFeedbackFormToString = (feedbackForm: AutomaticRenewalFeedbackForm) => {
    return Object.entries(feedbackForm)
        .filter(([_, value]) => value)
        .map(([key, value]) => {
            const formattedKey = FeedbackFormMap[key as FeedbackFormKeys];

            if (formattedKey === "OTHER_DETAILS" || formattedKey === "TECH_DETAILS") {
                return `${formattedKey}[${value}]`;
            }
            return formattedKey;
        })
        .join("\n");
};

const licensesContext = createContext<LicenseState>(initialState);

const LicensesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [state, setState] = useState(initialState);
    const axiosActions = useAxios();

    const getLicenses = useCallback(async () => {
        try {
            if (!state.data || state.refetch) {
                setState((prev) => ({
                    ...prev,
                    loading: true
                }));
                const { data } = await axiosActions.getData(null, "license");
                setState((prev) => ({
                    ...prev,
                    refetch: false,
                    loading: false,
                    data
                }));
            }
        } catch (e) {
            console.error(e);
            if (isMyError(e)) {
                setState((prev) => ({
                    ...prev,
                    loading: false,
                    error: e as MyError,
                    refetch: false
                }));
            }
        }
    }, [state.refetch, state.data]);

    const updateLicenseAutomaticRenewal = async (
        uuid: string,
        values: { automaticRenewal: boolean; feedbackForm?: string }
    ): Promise<void> => {
        try {
            setState((prev) => ({
                ...prev,
                loading: true
            }));

            await axiosActions.putData(null, `license/${uuid}/automatic-renewal`, values);
            setState((prev) => ({
                ...prev,
                loading: false
            }));
            refetchData();
        } catch (e) {
            console.error(e);
            if (isMyError(e)) {
                setState((prev) => ({
                    ...prev,
                    loading: false,
                    error: e as MyError,
                    refetch: false
                }));
            }
        }
    };

    useEffect(() => {
        getLicenses();
    }, [state.refetch, state.data]);

    const refetchData = () => {
        setState((prev) => ({ ...prev, refetch: true }));
    };

    return (
        <licensesContext.Provider
            value={{
                ...state,
                getLicenses,
                refetchData,
                updateLicenseAutomaticRenewal
            }}
        >
            {children}
        </licensesContext.Provider>
    );
};

export { licensesContext, LicensesProvider };
