import React, { useEffect, useRef } from "react";

import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { atom, useRecoilValue } from "recoil";

import { PreviewTask } from "./Preview";
import CustomButton from "../../../../../components/button/button";
import { Checkbox } from "../../../../../components/checkbox/checkbox";
import { useDragNDrop } from "../../../../../components/dragNDrop/useDragNDrop";
import { Radio } from "../../../../../components/radio/radio";
import Tooltip from "../../../../../components/tooltip/Tooltip";
import useWindowDimensions from "../../../../../hook/useWindowDimensions";
import { ReactComponent as IconAnonymization } from "../../../../../static/icons/tasks/icon-anonymization.svg";
import { ReactComponent as IconCheck } from "../../../../../static/icons/tasks/icon-check.svg";
import { ReactComponent as BlurType_Blured } from "../../../../../static/icons/tasks/icon_blur-type_blured.svg";
import { ReactComponent as BlurType_Opaque } from "../../../../../static/icons/tasks/icon_blur-type_opaque.svg";
import { ReactComponent as BlurType_Pixelated } from "../../../../../static/icons/tasks/icon_blur-type_pixelated.svg";
import { ReactComponent as FaceInactive } from "../../../../../static/icons/tasks/icon_face.svg";
import { ReactComponent as FaceActive } from "../../../../../static/icons/tasks/icon_face_active.svg";
import { ReactComponent as PlatesInactive } from "../../../../../static/icons/tasks/icon_plates.svg";
import { ReactComponent as PlatesGreen } from "../../../../../static/icons/tasks/icon_plates_active.svg";
import { FormAskAnonymization, BlurOptionsKey, BlurTypeKey } from "../../../../../types";

const ActionButtons: React.FC<BlurOptionsProps> = ({ onConfirmTask, onCancelTask }) => {
    const formContext = useFormikContext<FormAskAnonymization>();
    const { errors } = formContext;
    const canSubmit = Object.keys(errors).length === 0 && onConfirmTask;
    const onSubmit = () => {
        if (onConfirmTask) onConfirmTask(formContext.values);
    };
    return (
        <div className="d-flex justify-content-center">
            <CustomButton
                classNameType="cancel"
                buttonText="Cancel"
                customClass="mr-1"
                onClick={onCancelTask}
            />
            <CustomButton
                classNameType="secondary"
                buttonText="Create"
                disabled={!canSubmit}
                onClick={onSubmit}
                icon={<IconCheck width={13} />}
            />
        </div>
    );
};

export type BlurOptionsStep = Record<
    "select-file" | "blur-settings",
    {
        state: "inactive" | "active";
    }
>;

export const defaultOptionsSettingsState: BlurOptionsStep = {
    "select-file": {
        state: "active"
    },
    "blur-settings": {
        state: "inactive"
    }
};

export const optionsSettingsState = atom<BlurOptionsStep>({
    key: "optionsSettings",
    default: defaultOptionsSettingsState
});

const OptionRowCases = ["anonymization-mode", "blur-options", "blur-type"] as const;
type OptionRowCase = (typeof OptionRowCases)[number];

type OptionRowExtractProps<T extends OptionRowCase> = {
    value: BlurOptionsKey | BlurTypeKey;
    type: T extends "anonymization-mode" | "blur-type" ? "radio" : "checkbox";
};
type OptionRowProps<T extends OptionRowCase> = {
    icons: {
        active: () => JSX.Element;
        inactive: () => JSX.Element;
    };
    activated: boolean;
    text: string;
    onClickToggle: (e: React.ChangeEvent<HTMLInputElement>) => void;
    isChecked?: boolean;
    tooltip?: string;
    disabled?: boolean;
    customClass?: string;
} & OptionRowExtractProps<T>;

const OptionRow = <T extends OptionRowCase>({
    icons,
    activated,
    text,
    value,
    onClickToggle,
    type,
    isChecked,
    tooltip,
    disabled,
    customClass
}: OptionRowProps<T>) => {
    const { width } = useWindowDimensions();
    const inputRef = useRef<HTMLInputElement | null>(null);
    const { active: LogoActive, inactive: LogoInactive } = icons;
    const Logo = activated && isChecked ? LogoActive : LogoInactive;
    const handleMainDivClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (inputRef.current) {
            inputRef.current.click(); // Programmatically trigger a click event on the input element
        }
    };
    const getClassName = (
        activated: boolean,
        isDisabled: boolean | undefined,
        isChecked: boolean | undefined,
        customClass?: string
    ) => {
        return `filter-container__step__blur-settings__option-row d-flex ${activated ? "filter-container__step__blur-settings__option-row--activated opacity-100" : "opacity-50"} ${isChecked ? "filter-container__step__blur-settings__option-row--checked" : ""} ${isDisabled ? "filter-container__step__blur-settings__option-row--disabled" : ""} ${customClass || ""}`.trim();
    };

    return (
        <div style={{ cursor: disabled ? "not-allowed" : "initial" }} className="h-100">
            <div
                onClick={handleMainDivClick}
                className={getClassName(activated, disabled, isChecked, customClass)}
            >
                <div className={`filter-container__step__blur-settings__option-row_title`}>
                    <div
                        className={`filter-container__step__blur-settings__option-row__logo ${type === "radio" ? "d-flex flex-column" : ""}`}
                    >
                        <div
                            className={`d-flex align-items-center justify-content-center h-100 w-100`}
                        >
                            <Logo />
                        </div>
                        {type === "radio" && (
                            <div className="d-flex align-items-center gap-1">
                                {isChecked && <IconCheck width={12} height={12} />}
                                <span className="color-clear-blue-grey text-center">{text}</span>
                            </div>
                        )}
                    </div>
                    <div
                        className={`w-100 d-flex align-items-center h-100 w-100 filter-container__step__blur-settings__option-row__text duration-200 ease-in-out ${type === "radio" ? "d-none" : " ml-2 align-items-center"}`}
                    >
                        {type === "checkbox" && (
                            <span className="color-clear-blue-grey">{text}</span>
                        )}
                        {tooltip && width > 992 && (
                            <Tooltip content={tooltip} disabled={!activated}>
                                <sup>
                                    <div className="filter-container__step__blur-settings__option-row__information">
                                        <small>i</small>
                                    </div>
                                </sup>
                            </Tooltip>
                        )}
                    </div>
                </div>
                <div className={`filter-container__step__blur-settings__option-row__checkbox`}>
                    {type === "checkbox" ? (
                        <Checkbox
                            value={value}
                            isDisabled={!activated}
                            onClick={onClickToggle}
                            customClass="my-0"
                            isChecked={isChecked}
                            inputRef={inputRef}
                        />
                    ) : (
                        <Radio
                            value={value}
                            customClass="d-none"
                            isDisabled={!activated}
                            onClick={onClickToggle}
                            isChecked={isChecked}
                            inputRef={inputRef}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export interface BlurOptionsProps {
    onConfirmTask: ((data: FormAskAnonymization) => void) | undefined;
    onCancelTask: () => void;
}

export const BlurSettings: React.FC<BlurOptionsProps> = ({ onConfirmTask, onCancelTask }) => {
    const state = useRecoilValue(optionsSettingsState);
    const stepState = state["blur-settings"].state;
    const isActive = stepState === "active";
    const formikContext = useFormikContext<FormAskAnonymization>();
    const { t } = useTranslation();
    const { width } = useWindowDimensions();
    const linkedOptions: BlurOptionsKey[] = ["activation_faces_blur", "activation_plates_blur"];

    const getCheckedLinkedOptions = () => {
        return linkedOptions.filter(
            (key) => formikContext.values[key as BlurOptionsKey]
        ) as BlurOptionsKey[];
    };

    const handleClickToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();
        const value = e.target.value as BlurOptionsKey;
        formikContext.setValues((prev) => ({ ...prev, [value]: !prev[value] }));
    };

    const handleToggle = (e: React.ChangeEvent<HTMLInputElement>, value: BlurOptionsKey) => {
        const isChecked = e.target.checked;

        if (linkedOptions.includes(value)) {
            const checkedLinkedOptions = getCheckedLinkedOptions();

            if (!isChecked && checkedLinkedOptions.length <= 1) {
                return;
            }
        }

        formikContext.setFieldValue(value, isChecked);
    };

    const handleClickBlurType = (value: BlurTypeKey) => {
        formikContext.setFieldValue("blur_type", value);
    };

    return (
        <div className={`filters-container__step`}>
            <h2 className="filters-container__step-title">
                <IconAnonymization width={35} />
                {t("task-management.tasks.anonymization.settings")}
            </h2>
            <div className="filter-container__step__blur-settings">
                <h3 className="filter-container__step__blur-settings-title">
                    {t("task-management.tasks.anonymization.detection")}
                </h3>
                <div className="row">
                    <div className="col-6 mb-3 mb-lg-0">
                        <OptionRow<"blur-options">
                            icons={{
                                active: () => <FaceActive width={50} />,
                                inactive: () => <FaceInactive width={50} />
                            }}
                            activated={isActive}
                            text={
                                width < 992
                                    ? t("task-management.tasks.anonymization.face-responsive")
                                    : t("task-management.tasks.anonymization.faces")
                            }
                            value={"activation_faces_blur"}
                            onClickToggle={(e) => handleToggle(e, "activation_faces_blur")}
                            type="checkbox"
                            isChecked={formikContext.values.activation_faces_blur}
                        />
                    </div>
                    <div className="col-6 mb-3 mb-lg-0">
                        <OptionRow<"blur-options">
                            icons={{
                                active: () => <PlatesGreen width={50} />,
                                inactive: () => <PlatesInactive width={50} />
                            }}
                            activated={isActive}
                            text={
                                width < 992
                                    ? t("task-management.tasks.anonymization.plates-responsive")
                                    : t("task-management.tasks.anonymization.plates")
                            }
                            value={"activation_plates_blur"}
                            onClickToggle={(e) => handleToggle(e, "activation_plates_blur")}
                            type="checkbox"
                            isChecked={formikContext.values.activation_plates_blur}
                        />
                    </div>
                    <div className="col-12 col-lg-6">
                        <OptionRow<"blur-options">
                            icons={{
                                active: () => <></>,
                                inactive: () => <></>
                            }}
                            activated={isActive}
                            text={
                                width < 992
                                    ? t("task-management.tasks.anonymization.json-responsive")
                                    : t("task-management.tasks.anonymization.json")
                            }
                            value={"output_detections_url"}
                            onClickToggle={handleClickToggle}
                            type="checkbox"
                            isChecked={formikContext.values.output_detections_url}
                            tooltip={t("task-management.tasks.anonymization.json-information")}
                            customClass="no-bg no-border checkbox-first"
                        />
                    </div>
                </div>
                <h3 className="filter-container__step__blur-settings-title">Anonymization</h3>
                <div className="row">
                    <div className="col-4 mb-3 mb-lg-0">
                        <OptionRow<"blur-type">
                            icons={{
                                active: () => <BlurType_Blured width={50} />,
                                inactive: () => <BlurType_Blured width={50} />
                            }}
                            activated={isActive}
                            customClass="blurtype"
                            text={t("task-management.tasks.anonymization.blur-effect")}
                            value={"blur"}
                            onClickToggle={() => handleClickBlurType("blur")}
                            type="radio"
                            isChecked={formikContext.values.blur_type === "blur"}
                        />
                    </div>
                    <div className="col-4 mb-3 mb-lg-0">
                        <OptionRow<"blur-type">
                            type="radio"
                            icons={{
                                active: () => <BlurType_Pixelated width={40} />,
                                inactive: () => <BlurType_Pixelated width={40} />
                            }}
                            activated={isActive}
                            customClass="blurtype"
                            text={t("task-management.tasks.anonymization.pixelate-effect")}
                            value={"pixelate"}
                            onClickToggle={() => handleClickBlurType("pixelate")}
                            isChecked={formikContext.values.blur_type === "pixelate"}
                        />
                    </div>
                    <div className="col-4 mb-3 mb-lg-0">
                        <OptionRow<"blur-type">
                            type="radio"
                            icons={{
                                active: () => <BlurType_Opaque width={60} />,
                                inactive: () => <BlurType_Opaque width={60} />
                            }}
                            activated={isActive}
                            customClass="blurtype"
                            text={t("task-management.tasks.anonymization.opaque-effect")}
                            value={"opaque"}
                            onClickToggle={() => handleClickBlurType("opaque")}
                            isChecked={formikContext.values.blur_type === "opaque"}
                        />
                    </div>
                </div>
            </div>

            <div className="row d-flex align-items-center justify-content-center">
                <p className="col-12 offset-md-2 laius text-center m-0 color-clear-blue-grey">
                    {t("task-management.tasks.image-processing")}
                </p>
            </div>
            <ActionButtons onConfirmTask={onConfirmTask} onCancelTask={onCancelTask} />
        </div>
    );
};

const InputOption: React.FC<BlurOptionsProps> = ({ onConfirmTask, onCancelTask }) => {
    const { setValues, setFieldTouched } = useFormikContext<FormAskAnonymization>();
    const { file } = useDragNDrop();

    useEffect(() => {
        setValues((prev) => ({
            ...prev,
            anonymization_mode: "auto",
            output_detections_url: false
        }));
        setFieldTouched("input_media", true);
    }, [file]);

    return <PreviewTask onConfirmTask={onConfirmTask} onCancelTask={onCancelTask} />;
};

export const BlurOptions: React.FC<BlurOptionsProps> = ({ onConfirmTask, onCancelTask }) => {
    return (
        <div className="filters-container">
            <InputOption onConfirmTask={onConfirmTask} onCancelTask={onCancelTask} />
        </div>
    );
};
