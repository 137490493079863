import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { useHeader } from "../../hook/useHeader";
import useWindowDimensions from "../../hook/useWindowDimensions";
import { TASKS_UNAVAILABLE_TYPES, serviceUsableState } from "../../recoil/services.atom";
import { convertSize } from "../../services/tools";
import { ReactComponent as ArrowBackSvg } from "../../static/icons/icon_fleche-color.svg";
import { ReactComponent as LogoMobile } from "../../static/images/logo-mobile.svg";
import CustomButton from "../button/button";
import { MenuDropdown } from "./components/MenuDropdown";
import "../../static/scss/header.scss";
import { useTrialConsumption } from "../../hook/useTrialConsumption";
import { ErrorHeaderCardExpired } from "../error/ErrorHeaderCardExpired";
import { ErrorHeaderServiceDisabled } from "../error/ErrorHeaderServiceDisabled";
import MenuMobile from "../mobile/menu/MenuMobile";

const Header: React.FC = () => {
    const { push } = useHistory();
    const location = useLocation();
    const { t } = useTranslation();
    const [iconState, setIconState] = useState({ color: "#333" });
    const [showMenu, setShowMenu] = useState<boolean>(false);
    const { isTrial } = useHeader();
    const { width } = useWindowDimensions();
    const serviceUsable = useRecoilValue(serviceUsableState);
    const { trialConsumption } = useTrialConsumption();

    const handleIconState = (mouseAction: string) => {
        if (mouseAction === "enter") {
            setIconState({ color: "#47dc95" });
        } else {
            setIconState({ color: "#333" });
        }
    };
    const displayHeaderTitle = () => {
        const pagesWithBackButton = [
            {
                paths: ["/account/plan/trial"],
                goTo: "/account/plan",
                isSubsection: false
            },
            {
                paths: ["/account/edit/account", "/account/edit/billing"],
                goTo: "/account",
                isSubsection: false
            },
            {
                paths: ["/account/plan/update"],
                goTo: "/account/plan",
                isSubsection: false
            },
            {
                paths: [
                    "/services/blurIt",
                    "/services/facelytics",
                    "/services/soiling",
                    "/services/orientation",
                    "/services/congestion",
                    "/services/watermark",
                    "/services/vehiclesAndPedestriansDetection",
                    "/services/identity",
                    "/services/fakeDetection",
                    "/services/anpr"
                ],
                goTo: "/services",
                isSubsection: true
            },
            {
                paths: ["/task-manager/anonymization", "/task-manager/anonymization/edit"],
                goTo: () => history.back(),
                isSubsection: true
            }
        ];

        const isPageWithGoBack = pagesWithBackButton.find((page) =>
            page.paths.find((path) => location.pathname.includes(path))
        );
        const title = location.pathname;
        let subsection = "";

        if (isPageWithGoBack && isPageWithGoBack.isSubsection) {
            subsection = location.pathname;
        }

        return (
            <>
                {isPageWithGoBack && (
                    <div className="mr-2 d-none d-lg-block">
                        <ArrowBackSvg
                            height={15}
                            width={15}
                            onMouseEnter={() => handleIconState("enter")}
                            onMouseLeave={() => handleIconState("leave")}
                            style={{
                                cursor: "pointer"
                            }}
                            fill={iconState.color}
                            onClick={() => {
                                if (typeof isPageWithGoBack.goTo === "string") {
                                    push(isPageWithGoBack.goTo);
                                } else {
                                    isPageWithGoBack.goTo();
                                }

                                setIconState({ color: "#333" });
                            }}
                        />
                    </div>
                )}
                <h1 className="mb-0 mr-1 main-title">
                    {/* TODO: Remove this condition when we have a dedicated page for task details */}
                    {width < 992 && location.pathname.includes("detail")
                        ? t(`header./task-manager/detail`)
                        : width < 992 && mobileSubsectionAsTitle.includes(location.pathname)
                          ? t(`header.${subsection}`)
                          : t(`header.${title}`)}
                </h1>

                {isPageWithGoBack && width < 992 && (
                    <button
                        type="button"
                        className="button btn-menu btn-header-task active"
                        onClick={() => {
                            if (typeof isPageWithGoBack.goTo === "string") {
                                push(isPageWithGoBack.goTo);
                            } else {
                                isPageWithGoBack.goTo();
                            }
                            setIconState({ color: "#333" });
                        }}
                    ></button>
                )}
            </>
        );
    };

    const handleMobileMenu = () => {
        setShowMenu(true);
    };
    const mobileSubsectionAsTitle: string[] = [
        "/task-manager/anonymization",
        "/task-manager/watermark"
    ];
    const mobileLinksCloseList: string[] = [
        "/task-manager/anonymization",
        "/task-manager/watermark",
        "/account/edit/account"
    ];

    const menuNavlinks = [
        {
            goTo: "/account",
            translation: t("menu-dropdown.manage-account")
        },
        {
            goTo: "/account/plan",
            translation: t("menu-dropdown.plan-and-payment")
        },
        {
            goTo: "/account/developer",
            translation: t("menu-dropdown.developer-and-documentation")
        }
    ];

    return (
        <header
            className={`header  px-lg-5 align-items-center justify-content-between${
                width < 992 && mobileLinksCloseList.includes(location.pathname)
                    ? " header-task"
                    : ""
            }`}
        >
            <div className="d-flex align-items-center">
                <div className="mr-1 d-flex d-lg-none d-xl-none">
                    <LogoMobile />
                </div>
                {displayHeaderTitle()}
            </div>
            <div className="d-flex align-items-center">
                {isTrial && (
                    <div className="d-none d-lg-flex d-xl-flex align-items-center trial-section me-4">
                        <span className="title">TRIAL VERSION - &nbsp; </span>
                        <span className="data mr-2">
                            {trialConsumption ? convertSize(trialConsumption) : null} remaining
                        </span>
                        <CustomButton
                            buttonType="button"
                            buttonText="Update plan"
                            onClick={() => push("/account/plan")}
                        />
                    </div>
                )}

                {serviceUsable.reason === TASKS_UNAVAILABLE_TYPES.CARD_EXPIRED && (
                    <div className="d-none d-lg-flex d-xl-flex me-4">
                        <ErrorHeaderCardExpired />
                    </div>
                )}
                {serviceUsable.reason === TASKS_UNAVAILABLE_TYPES.INACTIVE_SERVICES && (
                    <div className="d-none d-lg-flex d-xl-flex me-4">
                        <ErrorHeaderServiceDisabled />
                    </div>
                )}

                {width < 992 && mobileLinksCloseList.includes(location.pathname) ? null : (
                    <div className={`d-flex align-items-center`}>
                        <MenuDropdown navLinks={menuNavlinks} />
                        <CustomButton
                            buttonType="button"
                            buttonText=""
                            classNameType="menu"
                            customClass="d-block d-lg-none d-xl-none"
                            onClick={handleMobileMenu}
                        />
                    </div>
                )}
            </div>
            <MenuMobile active={showMenu} setActive={setShowMenu} />
        </header>
    );
};

export default Header;
