import React, { useRef, useState } from "react";

import { useFormikContext } from "formik";
import { Trans, useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";

import { BlurOptionsProps, BlurSettings, optionsSettingsState } from "./BlurOptions";
import CustomButton from "../../../../../components/button/button";
import DragNDrop from "../../../../../components/dragNDrop/DragNDrop";
import { useDragNDrop } from "../../../../../components/dragNDrop/useDragNDrop";
import RoundedLoader from "../../../../../components/loader/RoundedLoaderAnimated";
import { convertSize } from "../../../../../services/tools";
import { ReactComponent as IconAnonymization } from "../../../../../static/icons/tasks/icon-anonymization.svg";
import { ReactComponent as IconDrop } from "../../../../../static/icons/tasks/icon-drop.svg";
import { ReactComponent as IconFileError } from "../../../../../static/icons/tasks/icon-file-error.svg";
import { ReactComponent as IconFolder } from "../../../../../static/icons/tasks/icon-folder.svg";
import { ReactComponent as IconVideo } from "../../../../../static/icons/tasks/icon-video.svg";
import { ReactComponent as IconZip } from "../../../../../static/icons/tasks/icon-zip.svg";
import { FormAskAnonymization } from "../../../../../types";

export const pictureAuthorizedFormats = ["image/jpg", "image/jpeg", "image/png", "image/webp"];
export const videoAuthorizedFormats = [
    "video/x-matroska",
    "video/mp4",
    "video/quicktime",
    "video/x-msvideo"
];
import "../../../../../static/scss/anonymization.scss";

export const dirtyAuthorizedVideoFormats = ["mkv", "mov"]; // mimetype is unpredictable
export const veryDirtyAuthorizedVideoFormats = ["avi"]; // can't be preloaded

// eslint-disable-next-line react/display-name
const PreviewMedia: React.FC = React.memo(() => {
    const { isZip, isDirtyVideoAcceptedFormat } = useDragNDrop();
    const { t } = useTranslation();
    const [isPreviewLoading, setPreviewIsLoading] = useState(true);
    const [isPreviewLoadedWithError, setPreviewIsLoadedWithError] = useState(false);
    const timeoutRef = useRef<any>(null!);

    const { file } = useDragNDrop();

    if (!file) return null;

    if (isZip(file)) {
        return (
            <div className="preview-container__preview-file-container">
                <div className="preview-container__preview-file">
                    <IconZip width={150} />
                </div>
                <p className="preview-container__preview-file-text">
                    {file.name}
                    <span>({convertSize(file.size)})</span>
                </p>
            </div>
        );
    }

    if (isDirtyVideoAcceptedFormat(file, veryDirtyAuthorizedVideoFormats)) {
        return (
            <div className="preview-container__preview-file-container">
                <div className="preview-container__preview-file">
                    <IconFileError width={150} />
                </div>
                <p className="preview-container__preview-file-text">
                    {file.name}
                    <span>{t("task-management.tasks.anonymization.preview-unavailable")}</span>
                    <span>({convertSize(file.size)})</span>
                </p>
            </div>
        );
    }

    if (pictureAuthorizedFormats.includes(file.type)) {
        return (
            <>
                {isPreviewLoadedWithError ? (
                    <div className="preview-container__preview-file-container">
                        <div className="preview-container__preview-file">
                            <IconFileError width={150} />
                        </div>
                        <p className="preview-container__preview-file-text">
                            {file.name}
                            <span>
                                {t("task-management.tasks.anonymization.preview-unavailable")}
                            </span>
                            <span>({convertSize(file.size)})</span>
                        </p>
                    </div>
                ) : (
                    <div className="preview-container__preview-file-container">
                        <img
                            src={URL.createObjectURL(file)}
                            className="preview-container__preview-file"
                            onError={() => {
                                setPreviewIsLoadedWithError(true);
                            }}
                            alt="preview"
                        />
                        <div className="preview-container__preview-file-icon">
                            <IconDrop width={69} />
                        </div>
                        <p className="preview-container__preview-file-text">
                            {file.name}
                            <span>({convertSize(file.size)})</span>
                        </p>
                    </div>
                )}
            </>
        );
    }

    if (
        videoAuthorizedFormats.includes(file.type) ||
        isDirtyVideoAcceptedFormat(file, dirtyAuthorizedVideoFormats)
    ) {
        return (
            <>
                {isPreviewLoadedWithError ? (
                    <div className="preview-container__preview-file-container">
                        <div className="preview-container__preview-file">
                            <IconFileError width={150} />
                        </div>
                        <p className="preview-container__preview-file-text">
                            {file.name}
                            <span>
                                {t("task-management.tasks.anonymization.preview-unavailable")}
                            </span>
                            <span>({convertSize(file.size)})</span>
                        </p>
                    </div>
                ) : (
                    <div className="preview-container__preview-file-container">
                        <div className="preview-container__preview-file-loading">
                            <RoundedLoader isLoading={isPreviewLoading} />
                        </div>
                        <video
                            onLoadStart={() => {
                                setPreviewIsLoadedWithError(false);
                                timeoutRef.current = setTimeout(() => {
                                    setPreviewIsLoadedWithError(true);
                                }, 2000);
                            }}
                            onLoadedData={() => {
                                clearTimeout(timeoutRef.current);
                                setPreviewIsLoading(false);
                                setPreviewIsLoadedWithError(false);
                            }}
                            src={URL.createObjectURL(file)}
                            preload="auto"
                            className="preview-container__preview-file"
                        />
                        <div className="preview-container__preview-file-icon">
                            <IconVideo height={54} fill="white" />
                        </div>
                        <p className="preview-container__preview-file-text">
                            {file.name}
                            <span>({convertSize(file.size)})</span>
                        </p>
                    </div>
                )}
            </>
        );
    }
    return null;
});

export const PreviewTask: React.FC<BlurOptionsProps> = ({ onConfirmTask, onCancelTask }) => {
    const { inputRef, file, dragging } = useDragNDrop();
    const { t } = useTranslation();
    const formikContext = useFormikContext<FormAskAnonymization>();
    const setState = useSetRecoilState(optionsSettingsState);

    const onChangeFile = (file: File) => {
        formikContext.setValues((prev) => ({
            ...prev,
            input_media: file
        }));
        setState((prev) => ({
            ...prev,
            "blur-settings": {
                state: "active"
            }
        }));
    };

    const onClickInput = () => {
        inputRef?.current?.click();
    };

    return (
        <>
            <DragNDrop id="dnd-anonymization" onDrop={onChangeFile}>
                {file ? (
                    <div
                        className="preview-container file-uploaded"
                        onClick={(e) => e.preventDefault()}
                    >
                        <PreviewMedia />
                        <BlurSettings onConfirmTask={onConfirmTask} onCancelTask={onCancelTask} />
                    </div>
                ) : (
                    <div className={`preview-container ${dragging ? "dragging" : ""}`}>
                        <div className="preview-container__box">
                            <div className="drag-preview">
                                <IconDrop width={200} />
                                <p className="drag-preview__text">
                                    {t("task-management.tasks.anonymization.drag-and-drop")}
                                </p>
                            </div>
                            <div className="preview-container__laius gap-2">
                                <h2 className="preview-container__laius-title">
                                    <IconAnonymization width={35} />
                                    <Trans
                                        i18nKey={"task-management.tasks.anonymization.title"}
                                        components={{ span: <span /> }}
                                    ></Trans>
                                </h2>
                                <p>{t("task-management.tasks.anonymization.supported-files")}</p>
                                <p>{t("task-management.tasks.anonymization.size-limitation")}</p>
                            </div>
                            <div className="preview-container__icon">
                                <IconDrop width={200} />
                            </div>
                            <p className="preview-container__icon-text">
                                {t("task-management.tasks.anonymization.drop-laius")}
                            </p>
                            <div className="preview-button">
                                <CustomButton
                                    onClick={onClickInput}
                                    buttonText={t(
                                        "task-management.tasks.anonymization.choose-button"
                                    )}
                                    customClass="btn-tasks"
                                    icon={<IconFolder fill="white" width={16} />}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </DragNDrop>
        </>
    );
};
