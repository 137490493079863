import React from "react";

import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import CustomButton from "../../../../components/button/button";
import Input from "../../../../components/input/Input";
import { Client } from "../../../../context/clientContext";

export const ClientInformation: React.FC<{ clientValues: Client | null }> = ({ clientValues }) => {
    const { t } = useTranslation();
    const { push } = useHistory();

    const handlePageModification = () => {
        push(`account/edit`);
    };

    return (
        <div className="middle-container my-account-view">
            <div className="w-100 d-flex align-items-center justify-content-between mb-3 mb-lg-5">
                <div className="d-flex align-items-center">
                    <h2 className="mb-0 me-lg-4 account-title">
                        {t("account-management.client-information")}
                    </h2>
                </div>
                <div>
                    <CustomButton
                        disabled={!clientValues}
                        classNameType="mainWhite"
                        onClick={handlePageModification}
                        buttonText={t("account-management.modification")}
                        buttonType="button"
                    />
                </div>
            </div>
            <div className="d-flex flex-column gap-5">
                <section className="d-flex gap-3 flex-column justify-content-start">
                    <div>
                        <Input
                            inputType="email"
                            placeholder="john.doe@blurit.io"
                            readOnly
                            withLabel
                            withSkeleton
                            groupClassName={"inline mb-0"}
                            labelTranslation={t("account-management.mail")}
                            value={
                                clientValues
                                    ? clientValues?.mail
                                        ? clientValues?.mail
                                        : "-"
                                    : undefined
                            }
                        />
                    </div>
                    <div>
                        <Input
                            inputType="text"
                            placeholder="Walter"
                            readOnly
                            withLabel
                            withSkeleton
                            groupClassName={"inline mb-0"}
                            labelTranslation={t("account-management.last-name")}
                            value={
                                clientValues
                                    ? clientValues?.lastName
                                        ? clientValues?.lastName
                                        : "-"
                                    : undefined
                            }
                        />
                    </div>
                    <div>
                        <Input
                            inputType="text"
                            placeholder="John"
                            readOnly
                            withLabel
                            withSkeleton
                            groupClassName={"inline mb-0"}
                            labelTranslation={t("account-management.first-name")}
                            value={
                                clientValues
                                    ? clientValues?.firstName
                                        ? clientValues?.firstName
                                        : "-"
                                    : undefined
                            }
                        />
                    </div>
                    <div>
                        <Input
                            inputType="tel"
                            placeholder="0123456789"
                            readOnly
                            withLabel
                            withSkeleton
                            groupClassName={"inline mb-0"}
                            labelTranslation={t("account-management.phone")}
                            value={
                                clientValues
                                    ? clientValues?.phone
                                        ? clientValues?.phone
                                        : "-"
                                    : undefined
                            }
                        />
                    </div>
                </section>
                <section className="d-flex gap-3 flex-column justify-content-start">
                    <div>
                        <Input
                            inputType="text"
                            placeholder="5 Rue de l'Église"
                            readOnly
                            withLabel
                            withSkeleton
                            groupClassName={"inline mb-0"}
                            labelTranslation={t("account-management.address")}
                            value={
                                clientValues
                                    ? clientValues?.address
                                        ? clientValues?.address
                                        : "-"
                                    : undefined
                            }
                        />
                    </div>
                    <div>
                        <Input
                            inputType="text"
                            placeholder="Boulogne-Billancourt"
                            readOnly
                            withLabel
                            withSkeleton
                            groupClassName={"inline mb-0"}
                            labelTranslation={t("account-management.city")}
                            value={
                                clientValues
                                    ? clientValues?.city
                                        ? clientValues?.city
                                        : "-"
                                    : undefined
                            }
                        />
                    </div>
                    <div>
                        <Input
                            inputType="text"
                            placeholder="France"
                            readOnly
                            withLabel
                            withSkeleton
                            groupClassName={"inline mb-0"}
                            labelTranslation={t("account-management.country")}
                            value={
                                clientValues
                                    ? clientValues?.country
                                        ? clientValues?.country
                                        : "-"
                                    : undefined
                            }
                        />
                    </div>
                    <div>
                        <Input
                            inputType="text"
                            placeholder="5 Rue de l'Église"
                            readOnly
                            withLabel
                            withSkeleton
                            groupClassName={"inline mb-0"}
                            labelTranslation={t("account-management.postal-code")}
                            value={
                                clientValues
                                    ? clientValues?.postalCode
                                        ? clientValues?.postalCode
                                        : "-"
                                    : undefined
                            }
                        />
                    </div>
                </section>
                {clientValues?.type === "COMPANY" && (
                    <section className="d-flex gap-3 flex-column justify-content-start">
                        <div>
                            <Input
                                inputType="text"
                                placeholder="Blurit"
                                readOnly
                                withLabel
                                withSkeleton
                                groupClassName={"inline mb-0"}
                                labelTranslation={t("account-management.company-name")}
                                value={
                                    clientValues
                                        ? clientValues?.companyName
                                            ? clientValues?.companyName
                                            : "-"
                                        : undefined
                                }
                            />
                        </div>
                        <div>
                            <Input
                                inputType="text"
                                placeholder="12345"
                                readOnly
                                withLabel
                                withSkeleton
                                groupClassName={"inline mb-0"}
                                labelTranslation={t("account-management.company-id")}
                                value={
                                    clientValues
                                        ? clientValues?.companyId
                                            ? clientValues?.companyId
                                            : "-"
                                        : undefined
                                }
                            />
                        </div>
                        <div>
                            <Input
                                inputType="text"
                                placeholder="1234567890"
                                readOnly
                                withLabel
                                withSkeleton
                                groupClassName={"inline mb-0"}
                                labelTranslation={t("account-management.company-vat")}
                                value={
                                    clientValues
                                        ? clientValues?.vatNumber
                                            ? clientValues?.vatNumber
                                            : "-"
                                        : undefined
                                }
                            />
                        </div>
                    </section>
                )}
            </div>
        </div>
    );
};
