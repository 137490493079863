import "./textarea.scss";

export interface ITextarea {
    name: string;
    placeholder: string;
    value: string;
    isDisabled?: boolean;
    onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
    onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
    isRequired?: boolean;
    customClass?: string;
}

export const Textarea = ({
    name,
    placeholder,
    value,
    isDisabled,
    onChange,
    onBlur,
    isRequired,
    customClass
}: ITextarea) => {
    return (
        <textarea
            name={name}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            rows={4}
            cols={50}
            className={`form-control ${customClass || ""}`}
            disabled={isDisabled}
            required={isRequired}
        />
    );
};
